import FranceLigue2 from '@/icons/france-ligue-2'
import NationsLeague from '@/icons/nations-league'
import {
  Albania,
  Andorra,
  Argentina,
  ArgentinaPrimera,
  Armenia,
  Australia,
  AustraliaLeague,
  Austria,
  Baseball,
  Basketball,
  Belgium,
  BelgiumJupilerPro,
  Bolivia,
  Bosnia,
  Boxing,
  BoxingRed,
  Brazil,
  BrazilSerieA,
  Bundesliga,
  Bundesliga2,
  Canada,
  Cfl,
  ChampionsLeague,
  Championship,
  Chile,
  China,
  ClubFriendlies,
  Colombia,
  ConmebolLibertadores,
  ConmebolSudamericana,
  CopaAmerica,
  CostaRica,
  Crotia,
  CrotiaHnl,
  Cs2,
  Cyprus,
  CzechFnl,
  Czechia,
  Denmark,
  DenmarkSuperliga,
  Dota2,
  Ecuador,
  EersteDivisie,
  Egypt,
  England,
  EnglandLeague1,
  EuroLeague,
  EuropaConference,
  EuropaLeague,
  EuropeanChampionship,
  F1,
  FaCup,
  FaroeIslands,
  Finland,
  Football,
  FormulaRed,
  France,
  GameHeader,
  Georgia,
  Germany,
  Golf,
  GolfBall,
  Greece,
  Hockey,
  Hungary,
  Iceland,
  Iraq,
  Ireland,
  IrelandLeague,
  Israel,
  Italy,
  ItalySerieB,
  Jamaica,
  Japan,
  JapanLeague,
  Kazakhstan,
  Kosovo,
  LaLiga,
  Latvia,
  Lbt,
  League1,
  Lithuania,
  Lol,
  Macedonia,
  Mali,
  Malta,
  Mexico,
  MexicoLigaMx,
  Mlb,
  Mls,
  Mma,
  Montenegro,
  Morocco,
  Nba,
  Ncaab,
  Ncaaf,
  Netherlands,
  NetherlandsEredivisie,
  Nfl,
  Nhl,
  Nigeria,
  Norway,
  OlympicsParis,
  Panama,
  Paraguay,
  Peru,
  Poland,
  Portugal,
  PortugalLeague,
  PremierLeague,
  PuertoRico,
  Romania,
  SaudiLeague,
  Scotland,
  ScotlandPremiership,
  Serbia,
  SerieA,
  Slovakia,
  Slovenia,
  SouthSudan,
  Spain,
  SuperLig,
  Switzerland,
  SwitzerlandLeague,
  Turkey,
  Turkey1League,
  Ufc,
  Ukraine,
  UnitedStates,
  Uruguay,
  Us,
  Valorant,
  Venezuela,
  Wales,
  Xfl,
} from '../icons'
import { TEAM_LOGOS_NCAAB_BASKETBALL } from './basketball-ncaab-logos'
import { PLAYER_LIST } from './ufc-player-logos'
export const SPORTS_COUNTRIES_LEAGUES = {
  baseball: {
    usa: {
      mlb: {
        name: 'MLB',
        index: 1,
      },
    },
  },
  hockey: {
    usa: {
      nhl: {
        name: 'NHL',
        index: 1,
      },
    },
  },
  basketball: {
    usa: {
      nba: {
        name: 'NBA',
        index: 1,
      },
      ncaab: {
        name: 'NCAAB',
        index: 2,
      },
      'olympics basketball men': {
        name: 'Olympics Basketball Men',
        index: 3,
      },
      // euroleague: {
      //   name: 'EuroLeague',
      //   index: 3,
      // },
    },
  },
  football: {
    usa: {
      nfl: {
        name: 'NFL',
        index: 1,
      },
      ncaaf: {
        name: 'NCAAF',
        index: 2,
      },
      xfl: {
        name: 'XFL',
        index: 4,
      },
    },
    // canada: {
    //   cfl: {
    //     name: 'CFL',
    //     index: 3,
    //   },
    // },
  },
  boxing: {
    world: {
      matches: {
        name: 'Boxing',
        index: 1,
      },
    },
  },
  mma: {
    world: {
      ufc: {
        name: 'UFC',
        index: 1,
      },
    },
  },
  soccer: {
    world: {
      'fifa - world cup': {
        name: 'FIFA - World Cup',
        index: 9,
      },
      'uefa - champions league': {
        name: 'UEFA - Champions League',
        index: 10,
      },
      'uefa - europa league': {
        name: 'UEFA - Europa League',
        index: 11,
      },
    },
    england: {
      'england - premier league': {
        name: 'England - Premier League',
        index: 1,
      },
      'england - championship': {
        name: 'England - Championship',
        index: 6,
      },
      'england - fa cup': {
        name: 'England - FA Cup',
        index: 7,
      },
      'england - efl cup': {
        name: 'England - EFL Cup',
        index: 8,
      },
    },
    france: {
      'france - ligue 1': {
        name: 'France - Ligue 1',
        index: 3,
      },
    },
    germany: {
      'germany - bundesliga': {
        name: 'Germany - Bundesliga',
        index: 2,
      },
    },
    italy: {
      'italy - serie a': {
        name: 'Italy - Serie A',
        index: 4,
      },
    },
    spain: {
      'spain - la liga': {
        name: 'Spain - La Liga',
        index: 5,
      },
    },
    usa: {
      'usa - major league soccer': {
        name: 'USA - Major League Soccer',
        index: 12,
      },
    },
  },
  // esports: {
  //   cs2: {
  //     index: 1,
  //     name: 'CS2',
  //   },
  //   'dota 2': {
  //     index: 2,
  //     name: 'Dota 2',
  //   },
  //   'league of legends': {
  //     index: 3,
  //     name: 'League of Legends',
  //   },
  //   valorant: {
  //     index: 4,
  //     name: 'Valorant',
  //   },
  // },
}

export const LEAGUES = {
  mlb: {
    name: 'MLB',
    index: 1,
    logo: Mlb,
    abbreviated_name: {
      'Arizona Diamondbacks': 'ARI',
      'Atlanta Braves': 'ATL',
      'Baltimore Orioles': 'BAL',
      'Boston Red Sox': 'BOS',
      'Chicago Cubs': 'CHC',
      'Chicago White Sox': 'CWS',
      'Cincinnati Reds': 'CIN',
      'Cleveland Indians': 'CLE',
      'Colorado Rockies': 'COL',
      'Detroit Tigers': 'DET',
      'Florida Marlins': 'FLA',
      'Houston Astros': 'HOU',
      'Kansas City Royals': 'KAN',
      'Los Angeles Angels': 'LAA',
      'Los Angeles Dodgers': 'LAD',
      'Milwaukee Brewers': 'MIL',
      'Minnesota Twins': 'MIN',
      'New York Mets': 'NYM',
      'New York Yankees': 'NYY',
      'Oakland Athletics': 'OAK',
      'Philadelphia Phillies': 'PHI',
      'Pittsburgh Pirates': 'PIT',
      'San Diego Padres': 'SD',
      'San Francisco Giants': 'SF',
      'Seattle Mariners': 'SEA',
      'St. Louis Cardinals': 'STL',
      'Tampa Bay Rays': 'TB',
      'Texas Rangers': 'TEX',
      'Toronto Blue Jays': 'TOR',
      'Washington Nationals': 'WAS',
      'Cleveland Guardians': 'CLE',
      'Miami Marlins': 'MIA',
      'NL All-Stars': 'NL',
      'AL All-Stars': 'AL',
    },
  },
  nhl: {
    name: 'NHL',
    index: 1,
    logo: Nhl,
    abbreviated_name: {
      'Atlanta Flames': 'AFM ',
      'Anaheim Ducks': 'ANA ',
      'Arizona Coyotes': 'ARI ',
      'Atlanta Thrashers': 'ATL ',
      'Boston Bruins': 'BOS ',
      'Utah Hockey Club': 'UTAH ',
      'Buffalo Sabres': 'BUF ',
      'Carolina Hurricanes': 'CAR ',
      'Columbus Blue Jackets': 'CBJ ',
      'Calgary Flames': 'CGY ',
      'Chicago Blackhawks': 'CHI ',
      'Colorado Rockies': 'CLR ',
      'Colorado Avalanche': 'COL ',
      'Dallas Stars': 'DAL ',
      'Detroit Red Wings': 'DET ',
      'Edmonton Oilers': 'EDM ',
      'Florida Panthers': 'FLA ',
      'Los Angeles Kings': 'LAK ',
      'Minnesota Wild': 'MIN ',
      'Minnesota North Stars': 'MNS ',
      'Montreal Canadiens': 'MTL ',
      'New Jersey Devils': 'NJD ',
      'Nashville Predators': 'NSH ',
      'New York Islanders': 'NYI ',
      'New York Rangers': 'NYR ',
      'Ottawa Senators': 'OTT ',
      'Philadelphia Flyers': 'PHI ',
      'Phoenix Coyotes': 'PHX ',
      'Pittsburgh Penguins': 'PIT ',
      'Seattle Kraken': 'SEA ',
      'San Jose Sharks': 'SJS ',
      'St. Louis Blues': 'STL ',
      'Tampa Bay Lightning': 'TBL ',
      'Toronto Maple Leafs': 'TOR ',
      'Toronto St. Patricks': 'TSP ',
      'Vancouver Canucks': 'VAN ',
      'Vegas Golden Knights': 'VGK ',
      'Winnipeg Jets': 'WPG ',
      'Washington Capitals': 'WSH ',
    },
  },
  nba: {
    name: 'NBA',
    index: 1,
    logo: Nba,
    abbreviated_name: {
      'Atlanta Hawks': 'ATL',
      'Boston Celtics': 'BOS',
      'Brooklyn Nets': 'BKN',
      'Charlotte Hornets': 'CHA',
      'Chicago Bulls': 'CHI',
      'Cleveland Cavaliers': 'CLE',
      'Dallas Mavericks': 'DAL',
      'Denver Nuggets': 'DEN',
      'Detroit Pistons': 'DET',
      'Golden State Warriors': 'GSW',
      'Houston Rockets': 'HOU',
      'Indiana Pacers': 'IND',
      'Los Angeles Clippers': 'LAC',
      'Los Angeles Lakers': 'LAL',
      'Memphis Grizzlies': 'MEM',
      'Miami Heat': 'MIA',
      'Milwaukee Bucks': 'MIL',
      'Minnesota Timberwolves': 'MIN',
      'New Orleans Pelicans': 'NOP',
      'New York Knicks': 'NYK',
      'Oklahoma City Thunder': 'OKC',
      'Orlando Magic': 'ORL',
      'Philadelphia 76ers': 'PHI',
      'Phoenix Suns': 'PHX',
      'Portland Trail Blazers': 'POR',
      'Sacramento Kings': 'SAC',
      'San Antonio Spurs': 'SAS',
      'Toronto Raptors': 'TOR',
      'Utah Jazz': 'UTA',
      'Washington Wizards': 'WAS',
    },
  },
  'olympics basketball men': {
    name: 'Olympics Basketball Men',
    index: 1,
    logo: OlympicsParis,
    teamLogo: {
      Spain: Spain,
      Australia: Australia,
      Japan: Japan,
      Germany: Germany,
      Brazil: Brazil,
      France: France,
      Canada: Canada,
      Greece: Greece,
      'Puerto Rico': PuertoRico,
      'South Sudan': SouthSudan,
      China: China,
      USA: Us,
      Serbia: Serbia,
      Belgium: Belgium,
      Nigeria: Nigeria,
      Poland: Poland,
    },
  },
  MLS: {
    name: 'MLS',
    index: 1,
    logo: Mls,
    abbreviated_name: {
      'Atlanta United FC': 'ATL',
      'Austin FC': 'AUS',
      'CF Montreal': 'MTL',
      'Charlotte FC': 'CHL',
      'Chicago Fire FC': 'CHI',
      'Colorado Rapids': 'CLR',
      'Columbus Crew SC': 'COL',
      'Columbus Crew': 'COL',
      'D.C. United': 'DCU',
      'FC Cincinnati': 'CIN',
      'FC Dallas': 'DAL',
      'Houston Dynamo FC': 'HOU',
      'Inter Miami CF': 'MIA',
      'Los Angeles FC': 'LAF',
      'Los Angeles Galaxy': 'LAG',
      'Minnesota United FC': 'MIN',
      'Nashville SC': 'NSC',
      'New England Revolution': 'NER',
      'New York City': 'NYC',
      'New York Red Bulls': 'NYR',
      'Orlando City SC': 'ORL',
      'Philadelphia Union': 'PHI',
      'Portland Timbers': 'POT',
      'Real Salt Lake': 'RSL',
      'San Jose Earthquakes': 'SJE',
      'Seattle Sounders FC': 'SEA',
      'Sporting Kansas City': 'SKC',
      'St. Louis City SC': 'LOU',
      'Toronto FC': 'TFC',
      'Vancouver Whitecaps FC': 'VAN',
    },
  },
  ncaab: {
    name: 'NCAAB',
    index: 2,
    logo: Ncaab,
    abbreviated_name: {
      'Abilene Christian': 'ACU',
      'Utah Tech': 'UTU',
      'Air Force': 'AFA',
      Akron: 'AKR',
      Alabama: 'ALA',
      'Alabama A&M': 'AAMU',
      'Alabama State': 'ALST',
      'Alcorn State': 'ALCN',
      'American University': 'AMER',
      'Appalachian State': 'APP',
      Arizona: 'ARIZ',
      'Arizona State': 'ASU',
      Arkansas: 'ARK',
      'Arkansas Pine Bluff': 'UAPB',
      'Arkansas State': 'ARST',
      Army: 'ARMY',
      Auburn: 'AUB',
      'Austin Peay': 'APSU',
      BYU: 'BYU',
      'Ball State': 'BALL',
      Baylor: 'BAY',
      Bellarmine: 'BELL',
      Belmont: 'BEL',
      'Bethune Cookman': 'BCU',
      Binghamton: 'BING',
      'Boise State': 'BOIS',
      'Boston College': 'BC',
      'Boston University': 'BU',
      'Bowling Green': 'BGSU',
      Bradley: 'BRAD',
      Brown: 'BRWN',
      Bryant: 'BRY',
      Bucknell: 'BUCK',
      Buffalo: 'BUFF',
      Butler: 'BUT',
      'Cal Poly': 'CP',
      'Cal State Bakersfield': 'CSUB',
      'Cal State Fullerton': 'CSUF',
      'Cal State Northridge': 'CSUN',
      California: 'CAL',
      'California Baptist': 'CBU',
      Campbell: 'CAM',
      Canisius: 'CAN',
      'Central Arkansas': 'CARK',
      'Central Connecticut': 'CCSU',
      'Central Michigan': 'CMU',
      Charleston: 'COFC',
      'Charleston Southern': 'CHSO',
      Charlotte: 'CLT',
      Chattanooga: 'UTC',
      'Chicago State': 'CHST',
      Cincinnati: 'CIN',
      Clemson: 'CLEM',
      'Cleveland State': 'CLEV',
      'Coastal Carolina': 'CCU',
      Colgate: 'COLG',
      Colorado: 'COLO',
      'Colorado State': 'CSU',
      Columbia: 'COLU',
      Connecticut: 'CONN',
      'Coppin State': 'COPP',
      Cornell: 'COR',
      Creighton: 'CREI',
      Dartmouth: 'DART',
      Davidson: 'DAV',
      Dayton: 'DAY',
      DePaul: 'DEP',
      Delaware: 'DEL',
      'Delaware State': 'DSU',
      Denver: 'DEN',
      'Detroit Mercy': 'DETM',
      Drake: 'DRKE',
      Drexel: 'DREX',
      Duke: 'DUKE',
      Duquesne: 'DUQ',
      'East Carolina': 'ECU',
      'East Tennessee State': 'ETSU',
      'Eastern Illinois': 'EIU',
      'Eastern Kentucky': 'EKU',
      'Eastern Michigan': 'EMU',
      'Eastern Washington': 'EWU',
      Elon: 'ELON',
      Evansville: 'EVAN',
      Fairfield: 'FAIR',
      'Fairleigh Dickinson': 'FDU',
      Florida: 'FLA',
      'Florida A&M': 'FAMU',
      'Florida Atlantic': 'FAU',
      'Florida Gulf Coast': 'FGCU',
      'Florida International': 'FIU',
      'Florida State': 'FSU',
      Fordham: 'FOR',
      'Fresno State': 'FRES',
      Furman: 'FUR',
      'Gardner-Webb': 'GWEB',
      'George Mason': 'GMU',
      'George Washington': 'GW',
      Georgetown: 'GTWN',
      Georgia: 'UGA',
      'Georgia Southern': 'GASO',
      'Georgia State': 'GAST',
      'Georgia Tech': 'GT',
      Gonzaga: 'GONZ',
      Grambling: 'GRAM',
      'Grand Canyon': 'GCU',
      'Green Bay': 'GB',
      Hampton: 'HAMP',
      Harvard: 'HARV',
      Hawaii: 'HAW',
      'High Point': 'HPU',
      Hofstra: 'HOF',
      'Holy Cross': 'HC',
      Houston: 'HOU',
      'Houston Christian': 'HCU',
      Howard: 'HOW',
      IUPUI: 'IUPU',
      Idaho: 'IDHO',
      'Idaho State': 'IDST',
      Illinois: 'ILL',
      'Illinois State': 'ILST',
      'Incarnate Word': 'UIW',
      Indiana: 'IU',
      'Indiana State': 'INST',
      Iona: 'IONA',
      Iowa: 'IOWA',
      'Iowa State': 'ISU',
      'Jackson State': 'JKST',
      Jacksonville: 'JAX',
      'Jacksonville State': 'JVST',
      'James Madison': 'JMU',
      Kansas: 'KU',
      'Kansas City': 'KC',
      'Kansas State': 'KSU',
      'Kennesaw State': 'KENN',
      'Kent State': 'KENT',
      Kentucky: 'UK',
      LSU: 'LSU',
      'La Salle': 'LAS',
      Lafayette: 'LAF',
      Lamar: 'LAM',
      'Le Moyne': 'LEM',
      Lehigh: 'LEH',
      Liberty: 'LIB',
      Lindenwood: 'LIN',
      Lipscomb: 'LIP',
      'Little Rock': 'LR',
      'Long Beach State': 'LBSU',
      'Long Island University': 'LIU',
      Longwood: 'LONG',
      Louisiana: 'UL',
      'Louisiana Tech': 'LT',
      Louisville: 'LOU',
      'Loyola Chicago': 'LUC',
      'Loyola Maryland': 'L-MD',
      'Loyola Marymount': 'LMU',
      Maine: 'ME',
      Manhattan: 'MAN',
      Marist: 'MRST',
      Marquette: 'MARQ',
      Marshall: 'MRSH',
      Maryland: 'MD',
      'Maryland-Eastern Shore': 'UMES',
      Massachusetts: 'MASS',
      McNeese: 'MCN',
      Memphis: 'MEM',
      Mercer: 'MER',
      Merrimack: 'MRMK',
      'Miami Florida': 'MIA',
      'Miami Ohio': 'M-OH',
      Michigan: 'MICH',
      'Michigan State': 'MSU',
      'Middle Tennessee': 'MTSU',
      Milwaukee: 'MILW',
      Minnesota: 'MINN',
      'Mississippi State': 'MSST',
      'Mississippi Valley State': 'MVSU',
      Missouri: 'MIZ',
      'Missouri State': 'MOST',
      Monmouth: 'MONM',
      Montana: 'MONT',
      'Montana State': 'MTST',
      'Morehead State': 'MORE',
      'Morgan State': 'MORG',
      "Mount St. Mary's": 'MSM',
      'Murray State': 'MUR',
      NJIT: 'NJIT',
      Navy: 'NAVY',
      Nebraska: 'NEB',
      Nevada: 'NEV',
      'New Hampshire': 'UNH',
      'New Mexico': 'UNM',
      'New Mexico State': 'NMSU',
      'New Orleans': 'UNO',
      Niagara: 'NIA',
      Nicholls: 'NICH',
      'Norfolk State': 'NORF',
      'North Alabama': 'UNA',
      'North Carolina': 'UNC',
      'North Carolina A&T': 'NCAT',
      'North Carolina Central': 'NCCU',
      'North Carolina State': 'NCSU',
      'North Dakota': 'UND',
      'North Dakota State': 'NDSU',
      'North Florida': 'UNF',
      'North Texas': 'UNT',
      Northeastern: 'NE',
      'Northern Arizona': 'NAU',
      'Northern Colorado': 'UNCO',
      'Northern Illinois': 'NIU',
      'Northern Iowa': 'UNI',
      'Northern Kentucky': 'NKU',
      Northwestern: 'NU',
      'Northwestern State': 'NWST',
      'Notre Dame': 'ND',
      Oakland: 'OAK',
      Ohio: 'OHIO',
      'Ohio State': 'OSU',
      Oklahoma: 'OU',
      'Oklahoma State': 'OKST',
      'Old Dominion': 'ODU',
      'Ole Miss': 'MISS',
      Omaha: 'OMA',
      'Oral Roberts': 'ORU',
      Oregon: 'ORE',
      'Oregon State': 'ORST',
      Pacific: 'PAC',
      'Penn State': 'PSU',
      Pennsylvania: 'PENN',
      Pepperdine: 'PEPP',
      Pittsburgh: 'PITT',
      Portland: 'PORT',
      'Portland State': 'PRST',
      'Prairie View A&M': 'PV',
      Presbyterian: 'PRES',
      Princeton: 'PRIN',
      Providence: 'PROV',
      Purdue: 'PUR',
      'Purdue Fort Wayne': 'PFW',
      'Queens University': 'QUOC',
      Quinnipiac: 'QUIN',
      Radford: 'RAD',
      'Rhode Island': 'URI',
      Rice: 'RICE',
      Richmond: 'RICH',
      Rider: 'RID',
      'Robert Morris': 'RMU',
      Rutgers: 'RUTG',
      'SIU Edwardsville': 'SIUE',
      SMU: 'SMU',
      'Sacramento State': 'SAC',
      'Sacred Heart': 'SHU',
      "Saint Joseph's": 'JOES',
      'Saint Louis': 'SLU',
      "Saint Mary's": 'SMC',
      "Saint Peter's": 'SPU',
      'Sam Houston State': 'SHSU',
      Samford: 'SAM',
      'San Diego': 'USD',
      'San Diego State': 'SDSU',
      'San Francisco': 'SF',
      'San Jose State': 'SJSU',
      'Santa Clara': 'SCU',
      'Seattle U': 'SEA',
      'Seton Hall': 'HALL',
      Siena: 'SIE',
      'South Alabama': 'USA',
      'South Carolina': 'SC',
      'South Carolina State': 'SCST',
      'South Carolina Upstate': 'UPST',
      'South Dakota': 'SDAK',
      'South Dakota State': 'SDSU',
      'South Florida': 'USF',
      'Southeast Missouri State': 'SEMO',
      'Southeastern Louisiana': 'SELA',
      Southern: 'SOU',
      'Southern Illinois': 'SIU',
      'Southern Indiana': 'USI',
      'Southern Mississippi': 'USM',
      'Southern Utah': 'SUU',
      'St. Bonaventure': 'SBU',
      'St. Francis Pennsylvania': 'SFPA',
      "St. John's": 'SJU',
      'St. Thomas-Minnesota': 'STMN',
      Stanford: 'STAN',
      'Stephen F. Austin': 'SFA',
      Stetson: 'STET',
      Stonehill: 'STO',
      'Stony Brook': 'STBK',
      Syracuse: 'SYR',
      TCU: 'TCU',
      'Tarleton State': 'TAR',
      Temple: 'TEM',
      Tennessee: 'TENN',
      'Tennessee State': 'TNST',
      'Tennessee Tech': 'TNTC',
      Texas: 'TEX',
      'Texas A&M': 'TA&M',
      'Texas A&M Commerce': 'TAMC',
      'Texas A&M-Corpus Christi': 'AMCC',
      'Texas Southern': 'TXSO',
      'Texas State': 'TXST',
      'Texas Tech': 'TTU',
      'The Citadel': 'CIT',
      Toledo: 'TOL',
      Towson: 'TOW',
      Troy: 'TROY',
      Tulane: 'TULN',
      Tulsa: 'TLSA',
      UAB: 'UAB',
      UAlbany: 'ALB',
      'UC Davis': 'UCD',
      'UC Irvine': 'UCI',
      'UC Riverside': 'UCR',
      'UC San Diego': 'UCSD',
      'UC Santa Barbara': 'UCSB',
      UCF: 'UCF',
      UCLA: 'UCLA',
      UIC: 'UIC',
      'UL Monroe': 'ULM',
      UMBC: 'UMBC',
      'UMass Lowell': 'UML',
      'UNC Asheville': 'UNCA',
      'UNC Greensboro': 'UNCG',
      'UNC Wilmington': 'UNCW',
      UNLV: 'UNLV',
      USC: 'USC',
      'UT Arlington': 'UTA',
      'UT Martin': 'UTM',
      'UT Rio Grande Valley': 'RGV',
      UTEP: 'UTEP',
      UTSA: 'UTSA',
      Utah: 'UTAH',
      'Utah State': 'USU',
      'Utah Tech': 'UTU',
      'Utah Valley': 'UVU',
      VCU: 'VCU',
      VMI: 'VMI',
      Valparaiso: 'VAL',
      Vanderbilt: 'VAN',
      Vermont: 'UVM',
      Villanova: 'VILL',
      Virginia: 'UVA',
      'Virginia Tech': 'VT',
      Wagner: 'WAG',
      'Wake Forest': 'WAKE',
      Washington: 'WASH',
      'Washington State': 'WSU',
      'Weber State': 'WEB',
      'West Virginia': 'WVU',
      'Western Carolina': 'WCU',
      'Western Illinois': 'WIU',
      'Western Kentucky': 'WKU',
      'Western Michigan': 'WMU',
      'Wichita State': 'WICH',
      'William & Mary': 'W&M',
      Winthrop: 'WIN',
      Wisconsin: 'WIS',
      Wofford: 'WOF',
      'Wright State': 'WRST',
      Wyoming: 'WYO',
      Xavier: 'XAV',
      Yale: 'YALE',
      'Youngstown State': 'YSU',
    },
  },
  'formula 1': {
    name: 'Formula 1',
    index: 1,
    logo: FormulaRed,
  },
  pga: {
    name: 'PGA',
    index: 1,
    logo: GolfBall,
  },
  euroleague: {
    name: 'EuroLeague',
    index: 3,
    logo: EuroLeague,
  },
  nfl: {
    name: 'NFL',
    index: 1,
    logo: Nfl,
    abbreviated_name: {
      'Arizona Cardinals': 'ARZ',
      'Atlanta Falcons': 'ATL',
      'Baltimore Ravens': 'BAL',
      'Buffalo Bills': 'BUF',
      'Carolina Panthers': 'CAR',
      'Chicago Bears': 'CHI',
      'Cincinnati Bengals': 'CIN',
      'Cleveland Browns': 'CLE',
      'Dallas Cowboys': 'DAL',
      'Denver Broncos': 'DEN',
      'Detroit Lions': 'DET',
      'Green Bay Packers': 'GB',
      'Houston Texans': 'HOU',
      'Indianapolis Colts': 'IND',
      'Jacksonville Jaguars': 'JAC',
      'Kansas City Chiefs': 'KC',
      'Las Vegas Raiders': 'LV',
      'Los Angeles Chargers': 'LAC',
      'Los Angeles Rams': 'LAR',
      'Miami Dolphins': 'MIA',
      'Minnesota Vikings': 'MIN',
      'New England Patriots': 'NE',
      'New Orleans Saints': 'NO',
      'New York Giants': 'NYG',
      'New York Jets': 'NYJ',
      'Philadelphia Eagles': 'PHI',
      'Pittsburgh Steelers': 'PIT',
      'San Francisco 49ers': 'SF',
      'Seattle Seahawks': 'SEA',
      'Tampa Bay Buccaneers': 'TB',
      'Tennessee Titans': 'TEN',
      'Washington Commanders': 'WAS',
    },
  },
  ncaaf: {
    name: 'NCAAF',
    index: 2,
    logo: Ncaaf,
    abbreviated_name: {
      'Boston College': 'BC',
      Clemson: 'CLEM',
      Duke: 'DUKE',
      'Florida State': 'FSU',
      'Georgia Tech': 'GT',
      Louisville: 'LOU',
      'Miami Florida': 'MIA',
      'NC State': 'NCST',
      Pittsburgh: 'PITT',
      Syracuse: 'SYR',
      'North Carolina': 'UNC',
      Virginia: 'UVA',
      'Virginia Tech': 'VT',
      'Wake Forest': 'WAKE',
      Louisiana: 'UL',
      //B1G
      Illinois: 'ILL',
      Indiana: 'IND',
      Iowa: 'IOWA',
      Michigan: 'MICH',
      'Michigan State': 'MSU',
      Minnesota: 'MINN',
      Nebraska: 'NEB',
      Northwestern: 'NW',
      'Ohio State': 'OSU',
      'Penn State': 'PSU',
      Purdue: 'PUR',
      Rutgers: 'RUTG',
      Maryland: 'UMD',
      Wisconsin: 'WIS',
      //B12
      Baylor: 'BAY',
      'Iowa State': 'ISU',
      Kansas: 'KU',
      'Kansas State': 'KSU',
      Oklahoma: 'OKLA',
      'Oklahoma State': 'OKST',
      TCU: 'TCU',
      Texas: 'TEX',
      'Texas Tech': 'TTU',
      'West Virginia': 'WVU',
      //P12
      Arizona: 'ARIZ',
      'Arizona State': 'ASU',
      California: 'CAL',
      Colorado: 'COLO',
      Oregon: 'ORE',
      'Oregon State': 'ORST',
      Stanford: 'STAN',
      UCLA: 'UCLA',
      USC: 'USC',
      Utah: 'UTAH',
      Washington: 'WASH',
      'Washington State': 'WSU',
      'Western Kentucky': 'WKU',
      'Florida International': 'FIU',
      'North Carolina State': 'NC STATE',
      'Florida Atlantic': 'FAU',
      //SEC
      Alabama: 'BAMA',
      Arkansas: 'ARK',
      Auburn: 'AUB',
      Florida: 'FLA',
      Georgia: 'UGA',
      Kentucky: 'UK',
      LSU: 'LSU',
      'Ole Miss': 'MISS',
      'Mississippi State': 'MSST',
      Missouri: 'MIZ',
      'South Carolina': 'SC ',
      Tennessee: 'TENN',
      'Texas A&M': 'TAMU',
      Vanderbilt: 'VAN',
      //IND
      BYU: 'BYU',
      Army: 'ARMY',
      Massachusetts: 'UMASS',
      'Notre Dame': 'ND',
      'North Alabama': 'UNA',
      'Southeast Missouri State': 'SEMO',
      McNeese: 'MCNS',
      'Tarleton State': 'TSU',
      //AAC
      Cincinnati: 'CIN',
      Connecticut: 'CONN',
      ECU: 'ECU',
      Houston: 'HOU',
      Memphis: 'MEM',
      Navy: 'NAVY',
      UAB: 'UAB',
      SMU: 'SMU',
      'South Florida': 'USF',
      Temple: 'TEM',
      Tulane: '	TULN',
      Tulsa: 'TLSA',
      UCF: 'UCF',
      //CUSA
      Charlotte: 'CHAR',
      FAU: 'FAU',
      FIU: 'FIU',
      'Louisiana Tech': 'LT',
      Marshall: 'MRSH',
      'Middle Tennessee': 'MTSU',
      'North Texas': 'UNT',
      'Old Dominion': 'ODU',
      Rice: 'RICE',
      'Southern Miss': 'USM',
      UTEP: 'UTEP',
      UTSA: 'UTSA',
      'East Carolina': 'ECU',
      WKU: 'WKU',
      //MAC
      Akron: 'AKR',
      'Ball State': 'BALL',
      'Bowling Green': 'BGSU',
      Buffalo: 'BUFF',
      'Central Michigan': 'CMU',
      'Eastern Michigan': 'EMU',
      'Kent State': 'KENT',
      'Miami (OH)': 'M-OH',
      'Northern Illinois': 'NIU',
      Ohio: 'OHIO',
      Toledo: 'TOL',
      'Western Michigan': 'WMU',
      //MWC
      'Air Force': 'AFA',
      'Boise State': 'BSU',
      'Colorado State': 'CSU',
      'Fresno State': 'FRES',
      Hawaii: 'HAW',
      Nevada: 'NEV',
      'New Mexico': 'UNM',
      'San Diego State': 'SDSU',
      'San Jose State': 'SJSU',
      UNLV: 'UNLV',
      'Utah State': 'USU',
      Wyoming: 'WYO',
      //SBC
      'Appalachian State': 'APP',
      'Arkansas State': 'ARST',
      'Georgia Southern': 'GASO',
      'Georgia State': 'GSU',
      Idaho: 'IDHO',
      'Louisiana Lafayette': 'ULL',
      'UL Monroe': 'ULM',
      'New Mexico State': 'NMSU',
      'South Alabama': 'USA',
      'Texas State': 'TXST',
      Troy: 'TROY',
      //Big Sky	Big Sky
      'Cal Poly': 'CP',
      'Eastern Washington': 'EWU',
      'Idaho State': 'IDST',
      Montana: 'MONT',
      'Montana State': 'MTST',
      'North Dakota': 'UND',
      'Northern Arizona': 'NAU',
      'Northern Colorado': 'UNCO',
      'Portland State': 'PRST',
      'Sacramento State': 'SAC',
      'Southern Utah': 'SUU',
      'UC Davis': 'UCD',
      'Weber State': 'WEB',
      //Big South	Big South
      'Charleston Southern': 'CHSO',
      'Coastal Carolina': 'CCAR',
      'Gardner-Webb': 'WEBB',
      'Kennesaw State': 'KENN',
      Liberty: 'LIB',
      Monmouth: 'MONM',
      Presbyterian: 'PRE',
      CAA: 'CAA',
      Albany: 'ALBY',
      Delaware: 'DEL',
      Elon: 'ELON',
      'James Madison': 'JMU',
      Maine: 'MNE',
      'New Hampshire': 'UNH',
      'Rhode Island': 'URI',
      Richmond: 'RICH',
      'Stony Brook': 'STON',
      Towson: 'TOWS',
      Villanova: 'NOVA',
      'Long Island University': 'LIU',
      'William & Mary': 'W&M',
      'Ivy League': 'Ivy',
      Brown: 'BRWN',
      Cornell: 'COR',
      Columbia: 'CLMB',
      Dartmouth: 'DART',
      Harvard: 'HARV',
      UPenn: 'PENN',
      Princeton: 'PRIN',
      Yale: 'YALE',
      MEAC: 'MEAC',
      'Bethune-Cookman': 'COOK',
      'Delaware State': 'DSU',
      'Florida A&M': 'FAMU',
      Hampton: 'HAMP',
      Howard: 'HOW',
      'Morgan State': 'MORG',
      'Norfolk State': 'NORF',
      'North Carolina A&T': 'NCAT',
      'NC Central': 'NCCU',
      'Savannah State': 'SAV',
      'South Carolina State': 'SCST',
      'Missouri Valley': 'MVFC',
      'Illinois State': 'ILST',
      'Indiana State': 'INST',
      'Missouri State': 'MOST',
      'North Dakota State': 'NDSU',
      'Northern Iowa': 'UNI',
      'South Dakota': 'SDAK',
      'South Dakota State': 'SDSU',
      'Southern Illinois': 'SIU',
      'Western Illinois': 'WIU',
      'Youngstown State': 'YSU',
      Northeast: 'NEC',
      Bryant: 'BRY',
      'Central Connecticut': 'CCSU',
      Duquesne: 'DUQ',
      'Robert Morris (PA)': 'RMU',
      'Sacred Heart': 'SHU',
      'St. Francis (PA)': 'SFU',
      Wagner: 'WAG',
      'Ohio Valley': 'OVC',
      'Austin Peay': 'PEAY',
      'Eastern Illinois': 'EIU',
      Lindenwood: 'LIN',
      'Eastern Kentucky': 'EKY',
      'Jacksonville State': 'JVST',
      'Murray State': 'MURR',
      'Southeast Missouri': 'SEMO',
      'Tennessee State': 'TNST',
      'Tennessee Tech': 'TNTC',
      'Tennessee-Martin': 'UTM',
      //Patriot	Patriot
      Bucknell: 'BUCK',
      Colgate: 'COLG',
      Fordham: 'FOR',
      Georgetown: 'GTWN',
      'Holy Cross': 'HC',
      Lafayette: 'LAF',
      Lehigh: 'LEH',
      Pioneer: 'Pioneer',
      Butler: 'BUT',
      Campbell: 'CAMP',
      Davidson: 'DAV',
      Dayton: 'DAY',
      Drake: 'DRKE',
      Jacksonville: 'JAC',
      Marist: 'MRST',
      'Morehead State': 'MORE',
      'San Diego': 'USD',
      Stetson: 'STET',
      Valparaiso: 'VALP',
      SoCon: 'SoCon',
      Chattanooga: 'CHAT',
      ETSU: 'ETSU',
      Furman: 'FUR',
      Mercer: 'MER',
      Samford: 'SAM',
      'The Citadel': 'CIT',
      'East Tennessee State': 'ETSU',
      VMI: 'VMI',
      'Western Carolina': 'WCU',
      Wofford: 'WOF',
      Southland: 'Southland',
      'Abilene Christian': 'ACU',
      'Utah Tech': 'UTU',
      'Central Arkansas': 'UCA',
      'Houston Baptist': 'HBU',
      'Incarnate Word': 'IW',
      Lamar: 'LAM',
      'McNeese State': 'MCNS',
      'Nicholls State': 'NICH',
      'Northwestern State': 'NWST',
      'Sam Houston State': 'SHSU',
      'Southeastern Louisiana': 'SELA',
      'Stephen F. Austin': 'SFA',
      SWAC: 'SWAC',
      'Alabama A&M': 'AAMU',
      'Alabama State': 'ALST',
      'Alcorn State': 'ALCN',
      'Arkansas-Pine Bluff': 'ARPB',
      'Grambling State': 'GRAM',
      'Jackson State': 'JKST',
      'Mississippi Valley State': 'MVSU',
      'Prairie View A&M': 'PV',
      'Southern University': 'SOU',
      'Texas Southern': 'TXSO',
    },
    teamLogo: {
      AFC: 'afc',
      'Air Force': 'air_force_falcons',
      Akron: 'akron_zips',
      Alabama: 'alabama_crimson_tide',
      'Appalachian State': 'appalachian_state_mountaineers',
      Arizona: 'arizona_wildcats',
      'Arizona Cardinals': 'arizona_cardinals',
      'Arizona State': 'arizona_state_sun_devils',
      Arkansas: 'arkansas_razorbacks',
      'Arkansas State': 'arkansas_state_red_wolves',
      'Arlington Renegades': null,
      Army: 'army_black_knights',
      'Atlanta Falcons': 'atlanta_falcons',
      Auburn: 'auburn_tigers',
      'BC Lions': null,
      BYU: 'byu_cougars',
      'Ball State': 'ball_state_cardinals',
      'Baltimore Ravens': 'baltimore_ravens',
      Baylor: 'baylor_bears',
      'Boise State': 'boise_state_broncos',
      'Boston College': 'boston_college_eagles',
      'Bowling Green': 'bowling_green_falcons',
      Buffalo: 'buffalo_bulls',
      'Buffalo Bills': 'buffalo_bills',
      'Calgary Stampeders': null,
      California: 'california_golden_bears',
      'Carolina Panthers': 'carolina_panthers',
      'Central Michigan': 'central_michigan_chippewas',
      Charlotte: 'charlotte_49ers',
      'Chicago Bears': 'chicago_bears',
      Cincinnati: 'cincinnati_bearcats',
      'Cincinnati Bengals': 'cincinnati_bengals',
      Clemson: 'clemson_tigers',
      'Cleveland Browns': 'cleveland_browns',
      'Coastal Carolina': 'coastal_carolina_chanticleers',
      Colorado: 'colorado_buffaloes',
      'Colorado State': 'colorado_state_rams',
      Connecticut: 'uconn_huskies',
      'DC Defenders': null,
      'Dallas Cowboys': 'dallas_cowboys',
      'Denver Broncos': 'denver_broncos',
      'Detroit Lions': 'detroit_lions',
      Duke: 'duke_blue_devils',
      'East Carolina': 'east_carolina_pirates',
      'Eastern Michigan': 'eastern_michigan_eagles',
      'Edmonton Elks': null,
      Florida: 'florida_gators',
      'Florida Atlantic': 'florida_atlantic_owls',
      'Florida International': 'florida_international_panthers',
      'Florida State': 'florida_state_seminoles',
      'Fresno State': 'fresno_state_bulldogs',
      Georgia: 'georgia_bulldogs',
      'Georgia Southern': 'georgia_southern_eagles',
      'Georgia State': 'georgia_state_panthers',
      'Georgia Tech': 'georgia_tech_yellow_jackets',
      'Green Bay Packers': 'green_bay_packers',
      'Hamilton Tiger-Cats': null,
      Hawaii: 'hawai_i_rainbow_warriors',
      Houston: 'houston_cougars',
      'Houston Roughnecks': null,
      'Houston Texans': 'houston_texans',
      Illinois: 'illinois_fighting_illini',
      Indiana: 'indiana_hoosiers',
      'Indianapolis Colts': 'indianapolis_colts',
      Iowa: 'iowa_hawkeyes',
      'Iowa State': 'iowa_state_cyclones',
      'Jacksonville Jaguars': 'jacksonville_jaguars',
      'Jacksonville State': 'jacksonville_state_gamecocks',
      'James Madison': 'james_madison_dukes',
      Kansas: 'kansas_jayhawks',
      'Kansas City Chiefs': 'kansas_city_chiefs',
      'Kansas State': 'kansas_state_wildcats',
      'Kent State': 'kent_state_golden_flashes',
      Kentucky: 'kentucky_wildcats',
      LSU: 'lsu_tigers',
      Lafayette: 'lafayette_leopards',
      'Las Vegas Raiders': 'las_vegas_raiders',
      Liberty: 'liberty_flames',
      'Los Angeles Chargers': 'los_angeles_chargers',
      'Los Angeles Rams': 'los_angeles_rams',
      Louisiana: 'louisiana_ragin_cajuns',
      'Louisiana Tech': 'louisiana_tech_bulldogs',
      Louisville: 'louisville_cardinals',
      Marshall: 'marshall_thundering_herd',
      Maryland: 'maryland_terrapins',
      Massachusetts: 'massachusetts_minutemen',
      Memphis: 'memphis_tigers',
      'Miami Dolphins': 'miami_dolphins',
      'Miami Florida': 'miami_hurricanes',
      'Miami Ohio': 'miami_oh_redhawks',
      Michigan: 'michigan_wolverines',
      'Michigan State': 'michigan_state_spartans',
      'Middle Tennessee': 'middle_tennessee_blue_raiders',
      Minnesota: 'minnesota_golden_gophers',
      'Minnesota Vikings': 'minnesota_vikings',
      'Mississippi State': 'mississippi_state_bulldogs',
      Missouri: 'missouri_tigers',
      'Montreal Alouettes': null,
      NFC: 'nfc',
      Navy: 'navy_midshipmen',
      Nebraska: 'nebraska_cornhuskers',
      Nevada: 'nevada_wolf_pack',
      'New England Patriots': 'new_england_patriots',
      'New Mexico': 'new_mexico_lobos',
      'New Mexico State': 'new_mexico_state_aggies',
      'New Orleans Saints': 'new_orleans_saints',
      'New York Giants': 'new_york_giants',
      'New York Jets': 'new_york_jets',
      'North Carolina': 'north_carolina_tar_heels',
      'North Carolina State': 'nc_state_wolfpack',
      'North Texas': 'north_texas_mean_green',
      'Northern Illinois': 'northern_illinois_huskies',
      Northwestern: 'northwestern_wildcats',
      'Notre Dame': 'notre_dame_fighting_irish',
      Ohio: 'ohio_bobcats',
      'Ohio State': 'ohio_state_buckeyes',
      Oklahoma: 'oklahoma_sooners',
      'Oklahoma State': 'oklahoma_state_cowboys',
      'Old Dominion': 'old_dominion_monarchs',
      'Ole Miss': 'ole_miss_rebels',
      Oregon: 'oregon_ducks',
      'Oregon State': 'oregon_state_beavers',
      'Orlando Guardians': null,
      'Ottawa Redblacks': null,
      'Penn State': 'penn_state_nittany_lions',
      'Philadelphia Eagles': 'philadelphia_eagles',
      Pittsburgh: 'pittsburgh_panthers',
      'Pittsburgh Steelers': 'pittsburgh_steelers',
      Purdue: 'purdue_boilermakers',
      Rice: 'rice_owls',
      Rutgers: 'rutgers_scarlet_knights',
      SMU: 'smu_mustangs',
      'Sam Houston State': 'sam_houston_bearkats',
      'San Antonio Brahmas': null,
      'San Diego State': 'san_diego_state_aztecs',
      'San Francisco 49ers': 'san_francisco_49ers',
      'San Jose State': 'san_jos_state_spartans',
      'Saskatchewan Roughriders': null,
      'Seattle Sea Dragons': null,
      'Seattle Seahawks': 'seattle_seahawks',
      'South Alabama': 'south_alabama_jaguars',
      'South Carolina': 'south_carolina_gamecocks',
      'South Florida': 'south_florida_bulls',
      'Southern Mississippi': 'southern_miss_golden_eagles',
      'St. Louis Battlehawks': null,
      Stanford: 'stanford_cardinal',
      Syracuse: 'syracuse_orange',
      TCU: 'tcu_horned_frogs',
      'Tampa Bay Buccaneers': 'tampa_bay_buccaneers',
      Temple: 'temple_owls',
      Tennessee: 'tennessee_volunteers',
      'Tennessee Titans': 'tennessee_titans',
      Texas: 'texas_longhorns',
      'Texas A&M': 'texas_a_m_aggies',
      'Texas A&M Commerce': 'texas_a_m-commerce_lions',
      'Texas State': 'texas_state_bobcats',
      'Texas Tech': 'texas_tech_red_raiders',
      Toledo: 'toledo_rockets',
      'Toronto Argonauts': null,
      Troy: 'troy_trojans',
      Tulane: 'tulane_green_wave',
      Tulsa: 'tulsa_golden_hurricane',
      UAB: 'uab_blazers',
      UCF: 'ucf_knights',
      UCLA: 'ucla_bruins',
      'UL Monroe': 'ul_monroe_warhawks',
      UNLV: 'unlv_rebels',
      USC: 'usc_trojans',
      UTEP: 'utep_miners',
      UTSA: 'utsa_roadrunners',
      Utah: 'utah_utes',
      'Utah State': 'utah_state_aggies',
      Vanderbilt: 'vanderbilt_commodores',
      'Vegas Vipers': null,
      Virginia: 'virginia_cavaliers',
      'Virginia Tech': 'virginia_tech_hokies',
      'Wake Forest': 'wake_forest_demon_deacons',
      Washington: 'washington_huskies',
      'Washington Commanders': 'washington_commanders',
      'Washington State': 'washington_state_cougars',
      'West Virginia': 'west_virginia_mountaineers',
      'Western Kentucky': 'western_kentucky_hilltoppers',
      'Western Michigan': 'western_michigan_broncos',
      'Winnipeg Blue Bombers': null,
      Wisconsin: 'wisconsin_badgers',
      Wyoming: 'wyoming_cowboys',
    },
  },
  ncaa: {
    name: 'NCAA',
    index: 2,
    logo: Ncaaf,
    abbreviated_name: {
      'Boston College': 'BC',
      Clemson: 'CLEM',
      Duke: 'DUKE',
      'Florida State': 'FSU',
      'Georgia Tech': 'GT',
      Louisville: 'LOU',
      'Miami Florida': 'MIA',
      'North Carolina State': 'NCST',
      Pittsburgh: 'PITT',
      Syracuse: 'SYR',
      'North Carolina': 'UNC',
      Virginia: 'UVA',
      'Virginia Tech': 'VT',
      'Wake Forest': 'WAKE',
      //B1G
      Illinois: 'ILL',
      Indiana: 'IND',
      Iowa: 'IOWA',
      Michigan: 'MICH',
      'Michigan State': 'MSU',
      Minnesota: 'MINN',
      Nebraska: 'NEB',
      Northwestern: 'NW',
      'Ohio State': 'OSU',
      'Penn State': 'PSU',
      Purdue: 'PUR',
      Rutgers: 'RUTG',
      Maryland: 'UMD',
      Wisconsin: 'WIS',
      //B12
      Baylor: 'BAY',
      'Iowa State': 'ISU',
      Kansas: 'KU',
      'Kansas State': 'KSU',
      Oklahoma: 'OKLA',
      'Oklahoma State': 'OKST',
      TCU: 'TCU',
      Texas: 'TEX',
      'Texas Tech': 'TTU',
      'West Virginia': 'WVU',
      //P12
      Arizona: 'ARIZ',
      'Arizona State': 'ASU',
      Cal: 'CAL',
      Colorado: 'COLO',
      Oregon: 'ORE',
      'Oregon State': 'ORST',
      Stanford: 'STAN',
      UCLA: 'UCLA',
      USC: 'USC',
      Utah: 'UTAH',
      Washington: 'WASH',
      'Washington State': 'WSU',
      //SEC
      Alabama: 'BAMA',
      Arkansas: 'ARK',
      Auburn: 'AUB',
      Florida: 'FLA',
      Georgia: 'UGA',
      Kentucky: 'UK',
      LSU: 'LSU',
      'Ole Miss': 'MISS',
      'Mississippi State': 'MSST',
      Missouri: 'MIZ',
      'South Carolina': 'SC ',
      Tennessee: 'TENN',
      'Texas A&M': 'TAMU',
      Vanderbilt: 'VAN',
      //IND
      BYU: 'BYU',
      Army: 'ARMY',
      Massachusetts: 'UMASS',
      'Notre Dame': 'ND',
      //AAC
      Cincinnati: 'CIN',
      Connecticut: 'CONN',
      ECU: 'ECU',
      Houston: 'HOU',
      Memphis: 'MEM',
      Navy: 'NAVY',
      SMU: 'SMU',
      'South Florida': 'USF',
      Temple: 'TEM',
      Tulane: '	TULN',
      Tulsa: 'TLSA',
      UCF: 'UCF',
      //CUSA
      Charlotte: 'CHAR',
      FAU: 'FAU',
      FIU: 'FIU',
      'Louisiana Tech': 'LT',
      Marshall: 'MRSH',
      'Middle Tennessee': 'MTSU',
      'North Texas': 'UNT',
      'Old Dominion': 'ODU',
      Rice: 'RICE',
      'Southern Miss': 'USM',
      UTEP: 'UTEP',
      UTSA: 'UTSA',
      WKU: 'WKU',
      //MAC
      Akron: 'AKR',
      'Ball State': 'BALL',
      'Bowling Green': 'BGSU',
      Buffalo: 'BUFF',
      'Central Michigan': 'CMU',
      'Eastern Michigan': 'EMU',
      'Kent State': 'KENT',
      'Miami Ohio': 'M-OH',
      'Northern Illinois': 'NIU',
      Ohio: 'OHIO',
      Toledo: 'TOL',
      'Western Michigan': 'WMU',
      //MWC
      'Air Force': 'AFA',
      'Boise State': 'BSU',
      'Colorado State': 'CSU',
      'Fresno State': 'FRES',
      Hawaii: 'HAW',
      Nevada: 'NEV',
      'New Mexico': 'UNM',
      'San Diego State': 'SDSU',
      'San Jose State': 'SJSU',
      UNLV: 'UNLV',
      'Utah State': 'USU',
      Wyoming: 'WYO',
      //SBC
      'Appalachian State': 'APP',
      'Arkansas State': 'ARST',
      'Georgia Southern': 'GASO',
      'Georgia State': 'GSU',
      Idaho: 'IDHO',
      'Louisiana Lafayette': 'ULL',
      'UL Monroe': 'ULM',
      'New Mexico State': 'NMSU',
      'South Alabama': 'USA',
      'Texas State': 'TXST',
      Troy: 'TROY',
      //Big Sky	Big Sky
      'Cal Poly': 'CP',
      'Eastern Washington': 'EWU',
      'Idaho State': 'IDST',
      Montana: 'MONT',
      'Montana State': 'MTST',
      'North Dakota': 'UND',
      'Northern Arizona': 'NAU',
      'Northern Colorado': 'UNCO',
      'Portland State': 'PRST',
      'Sacramento State': 'SAC',
      'Southern Utah': 'SUU',
      'UC Davis': 'UCD',
      'Weber State': 'WEB',
      //Big South	Big South
      'Charleston Southern': 'CHSO',
      'Coastal Carolina': 'CCAR',
      'Gardner-Webb': 'WEBB',
      'Kennesaw State': 'KENN',
      Liberty: 'LIB',
      Monmouth: 'MONM',
      Presbyterian: 'PRE',
      CAA: 'CAA',
      Albany: 'ALBY',
      Delaware: 'DEL',
      Elon: 'ELON',
      'James Madison': 'JMU',
      Maine: 'MNE',
      'New Hampshire': 'UNH',
      'Rhode Island': 'URI',
      Richmond: 'RICH',
      'Stony Brook': 'STON',
      Towson: 'TOWS',
      Villanova: 'NOVA',
      'William & Mary': 'W&M',
      'Ivy League': 'Ivy',
      Brown: 'BRWN',
      Cornell: 'COR',
      Columbia: 'CLMB',
      Dartmouth: 'DART',
      Harvard: 'HARV',
      UPenn: 'PENN',
      Princeton: 'PRIN',
      Yale: 'YALE',
      MEAC: 'MEAC',
      'Bethune-Cookman': 'COOK',
      'Delaware State': 'DSU',
      'Florida A&M': 'FAMU',
      Hampton: 'HAMP',
      Howard: 'HOW',
      'Morgan State': 'MORG',
      'Norfolk State': 'NORF',
      'North Carolina A&T': 'NCAT',
      'NC Central': 'NCCU',
      'Savannah State': 'SAV',
      'South Carolina State': 'SCST',
      'Missouri Valley': 'MVFC',
      'Illinois State': 'ILST',
      'Indiana State': 'INST',
      'Missouri State': 'MOST',
      'North Dakota State': 'NDSU',
      'Northern Iowa': 'UNI',
      'South Dakota': 'SDAK',
      'South Dakota State': 'SDSU',
      'Southern Illinois': 'SIU',
      'Western Illinois': 'WIU',
      'Youngstown State': 'YSU',
      Northeast: 'NEC',
      Bryant: 'BRY',
      'Central Connecticut': 'CCSU',
      Duquesne: 'DUQ',
      'Robert Morris (PA)': 'RMU',
      'Sacred Heart': 'SHU',
      'St. Francis (PA)': 'SFU',
      Wagner: 'WAG',
      'Ohio Valley': 'OVC',
      'Austin Peay': 'PEAY',
      'Eastern Illinois': 'EIU',
      'Eastern Kentucky': 'EKY',
      'Jacksonville State': 'JVST',
      'Murray State': 'MURR',
      'Southeast Missouri': 'SEMO',
      'Tennessee State': 'TNST',
      'Tennessee Tech': 'TNTC',
      'Tennessee-Martin': 'UTM',
      //Patriot	Patriot
      Bucknell: 'BUCK',
      Colgate: 'COLG',
      Fordham: 'FOR',
      Georgetown: 'GTWN',
      'Holy Cross': 'HC',
      Lafayette: 'LAF',
      Lehigh: 'LEH',
      Pioneer: 'Pioneer',
      Butler: 'BUT',
      Campbell: 'CAMP',
      Davidson: 'DAV',
      Dayton: 'DAY',
      Drake: 'DRKE',
      Jacksonville: 'JAC',
      Marist: 'MRST',
      'Morehead State': 'MORE',
      'San Diego': 'USD',
      Stetson: 'STET',
      Valparaiso: 'VALP',
      SoCon: 'SoCon',
      Chattanooga: 'CHAT',
      ETSU: 'ETSU',
      Furman: 'FUR',
      Mercer: 'MER',
      Samford: 'SAM',
      'The Citadel': 'CIT',
      VMI: 'VMI',
      'Western Carolina': 'WCU',
      Wofford: 'WOF',
      Southland: 'Southland',
      'Abilene Christian': 'ACU',
      'Utah Tech': 'UTU',
      'Central Arkansas': 'UCA',
      'Houston Baptist': 'HBU',
      'Incarnate Word': 'IW',
      Lamar: 'LAM',
      'McNeese State': 'MCNS',
      'Nicholls State': 'NICH',
      'Northwestern State': 'NWST',
      'Sam Houston State': 'SHSU',
      'Southeastern Louisiana': 'SELA',
      'Stephen F. Austin': 'SFA',
      SWAC: 'SWAC',
      'Alabama A&M': 'AAMU',
      'Alabama State': 'ALST',
      'Alcorn State': 'ALCN',
      'Arkansas-Pine Bluff': 'ARPB',
      'Grambling State': 'GRAM',
      'Jackson State': 'JKST',
      'Mississippi Valley State': 'MVSU',
      'Prairie View A&M': 'PV',
      'Southern University': 'SOU',
      'Texas Southern': 'TXSO',
      'North Carolina State': 'NCST',
      'East Carolina': 'ECU',
      'Western Kentucky': 'WKU',
      'St. Francis Pennsylvania': 'SFP',
    },
  },
  cfl: {
    name: 'CFL',
    index: 3,
    logo: Cfl,
    abbreviated_name: {
      'BC Lions': 'BC',
      'Calgary Stampeders': 'CGY',
      'Hamilton Tiger-Cats': 'HAM',
      'Winnipeg Blue Bombers': 'WPG',
      'Ottawa Redblacks': 'OTT',
      'Montreal Alouettes': 'MTL',
      'Saskatchewan Roughriders': 'SSK',
      'Edmonton Elks': 'EDM',
      'Toronto Argonauts': 'TOR',
    },
  },

  cs2: {
    index: 1,
    name: 'CS2',
    logo: Cs2,
  },
  'dota 2': {
    index: 2,
    name: 'Dota 2',
    logo: Dota2,
  },
  'league of legends': {
    index: 3,
    name: 'League of Legends',
    logo: Lol,
  },
  valorant: {
    index: 4,
    name: 'Valorant',
    logo: Valorant,
  },
  xfl: {
    name: 'XFL',
    index: 4,
    logo: Xfl,
  },
  matches: {
    name: 'Matches',
    index: 1,
    logo: BoxingRed,
  },
  ufc: {
    name: 'UFC',
    index: 1,
    logo: Ufc,
    player_images: {
      ...PLAYER_LIST,
    },
  },
  'england - premier league': {
    name: 'England - Premier League',
    index: 1,
    logo: PremierLeague,
    abbreviated_name: {
      'AFC Bournemouth': 'BOU',
      Arsenal: 'ARS',
      'Aston Villa': 'AVA',
      Brentford: 'BRE',
      'Brighton & Hove Albion': 'BRH',
      Chelsea: 'CHE',
      'Crystal Palace': 'CRY',
      Everton: 'EVE',
      Fulham: 'FUL',
      'Leeds United': 'LEE	',
      'Leicester City': 'LEI',
      Liverpool: 'LIV',
      'Manchester City': 'MCI',
      'Manchester United': 'MUN',
      'Newcastle United': 'NEW',
      'Nottingham Forest': 'NTG',
      Southampton: 'SOU',
      'Tottenham Hotspur': 'TOT',
      'West Ham United': 'WHU',
      'Wolverhampton Wanderers': 'WLV',
    },
  },
  'germany - bundesliga': {
    name: 'Germany - Bundesliga',
    index: 2,
    logo: Bundesliga,
    abbreviated_name: {
      'Bayer Leverkusen': 'B04',
      'Bayern Munich': 'BAY',
      'Borussia Dortmund': 'DOR',
      'Borussia Moenchengladbach': 'BMG',
      Cologne: 'CGN',
      'Eintracht Frankfurt': 'SGE',
      'FC Augsburg': 'AUG',
      Freiburg: 'SCF',
      'Hertha Berlin': 'BCS',
      Hoffenheim: 'TSG',
      'Mainz 05': 'MAI',
      'RB Leipzig': 'RBL',
      'Schalke 04': 'S04',
      'Union Berlin': 'UNB',
      'VfB Stuttgart': 'STU',
      'VfL Bochum': 'BOC',
      'VfL Wolfsburg': 'WOB',
      'Werder Bremen': 'SVW',
    },
  },
  'france - ligue 1': {
    name: 'France - Ligue 1',
    index: 3,
    logo: League1,
    abbreviated_name: {
      Ajaccio: 'ACA',
      Angers: 'ANG',
      Auxerre: 'AUX',
      Brest: 'B29',
      'Clermont Foot': 'CLE',
      Lille: 'LIL',
      Lorient: 'LOR',
      Monaco: 'AMO',
      Montpellier: 'MPL',
      Nantes: 'NAN',
      Nice: 'NCE',
      'Olympique Lyon': 'LYO',
      'Olympique Marseille': 'OLM',
      'Paris St Germain': 'PSG',
      'RC Lens': 'LEN',
      Reims: 'REI',
      Rennes: 'REN',
      Strasbourg: 'STR',
      Toulouse: 'TOU',
      Troyes: 'TRO',
    },
  },
  'italy - serie a': {
    name: 'Italy - Serie A',
    index: 4,
    logo: SerieA,
    abbreviated_name: {
      'AC Milan': 'MIL',
      'AS Roma': 'ROM',
      Atalanta: 'ATT',
      Bologna: 'BGN',
      Cremonese: 'CRE',
      Empoli: 'EMP',
      Fiorentina: 'FIO',
      'Hellas Verona': 'HEL',
      'Inter Milan': 'INT',
      Juventus: 'JUV',
      Lazio: 'LAZ',
      Lecce: 'LEC',
      Monza: 'MNZ',
      Napoli: 'NAP',
      Salernitana: 'USS',
      Sampdoria: 'SAM',
      Sassuolo: 'SAS',
      'Spezia Calcio': 'SPZ',
      Torino: 'TOR',
      Udinese: 'UDI',
    },
  },
  'serie a': {
    name: 'Italy - Serie A',
    index: 4,
    logo: SerieA,
    abbreviated_name: {
      'AC Milan': 'MIL',
      'AS Roma': 'ROM',
      Atalanta: 'ATT',
      Bologna: 'BGN',
      Cremonese: 'CRE',
      Empoli: 'EMP',
      Fiorentina: 'FIO',
      'Hellas Verona': 'HEL',
      'Inter Milan': 'INT',
      Juventus: 'JUV',
      Lazio: 'LAZ',
      Lecce: 'LEC',
      Monza: 'MNZ',
      Napoli: 'NAP',
      Salernitana: 'USS',
      Sampdoria: 'SAM',
      Sassuolo: 'SAS',
      'Spezia Calcio': 'SPZ',
      Torino: 'TOR',
      Udinese: 'UDI',
    },
  },
  'spain - la liga': {
    name: 'Spain - La Liga',
    index: 5,
    logo: LaLiga,
    abbreviated_name: {
      Almeria: 'UDA',
      'Athletic Bilbao': 'ATB',
      'Atletico Madrid': 'ATM',
      Barcelona: 'FCB',
      Cadiz: 'CCF',
      'Celta Vigo': 'CLV',
      Elche: 'ELC',
      Espanyol: 'ESY',
      Getafe: 'GET',
      Girona: 'GIR',
      Osasuna: 'OSA',
      'Rayo Vallecano': 'RAY',
      'RCD Mallorca': 'RCD',
      'Real Betis': 'BET',
      'Real Madrid': 'MAD',
      'Real Sociedad': 'SOC',
      'Real Valladolid': 'REV',
      Sevilla: 'SEV',
      Valencia: 'VAL',
      Villarreal: 'VIL',
    },
  },
  'england - championship': {
    name: 'England - Championship',
    index: 6,
    logo: Championship,
    abbreviated_name: {
      'AC Milan': 'MIL',
      'Ajax Amsterdam': 'AJA',
      'Atletico Madrid': 'ATM',
      Barcelona: 'FCB',
      'Bayer Leverkusen': 'B04',
      'Bayern Munich': 'BAY',
      Benfica: 'SLB',
      'Borussia Dortmund': 'DOR',
      Celtic: 'CEL',
      Chelsea: 'CHE',
      'Club Brugge': 'BRU',
      'Dinamo Zagreb': 'DZA',
      'Eintracht Frankfurt': 'SGE',
      'FC Copenhagen': 'COP',
      'FC Porto': 'POR',
      'FC Salzburg': 'RBS',
      'Inter Milan': 'INT',
      Juventus: 'JUV',
      Liverpool: 'LIV',
      'Maccabi Haifa': 'HAI',
      'Manchester City': 'MCI',
      Napoli: 'NAP',
      'Olympique Marseille': 'OLM',
      'Paris St Germain': 'PSG',
      Rangers: 'RFC',
      'RB Leipzig': 'RBL',
      'Real Madrid': 'MAD',
      Sevilla: 'SEV',
      'Shakhtar Donetsk': 'SHK',
      'Sporting Lisbon': 'SLI',
      'Tottenham Hotspur': 'TOT',
      'Viktoria Plzen': 'VPL',
      'Birmingham City': 'BRC',
      'Blackburn Rovers': 'BBR',
      Blackpool: 'BLK',
      'Bristol City': 'BSC',
      Burnley: 'BUR',
      'Cardiff City': 'CDF',
      'Coventry City': 'CVC',
      'Huddersfield Town': 'HDD',
      'Hull City': 'HUL',
      'Luton Town': 'LUT',
      Middlesbrough: 'MID',
      Millwall: 'MLW',
      'Norwich City': 'NOR',
      'Preston North End': 'PNE',
      'Queens Park Rangers': 'QPR',
      Reading: 'RDG',
      'Rotherham United': 'RTU	',
      'Sheffield United': 'SHU',
      'Stoke City': 'STK',
      Sunderland: 'SUN',
      'Swansea City': 'SWA',
      Watford: 'WAT',
      'West Bromwich Albion': 'WBA',
      'Wigan Athletic': 'WIG',
    },
  },
  'england - fa cup': {
    name: 'England - FA Cup',
    index: 7,
    logo: FaCup,
    abbreviated_name: {
      'Manchester City': 'MCI',
      'Manchester United': 'MUN',
    },
  },
  'england - efl cup': {
    name: 'England - EFL Cup',
    index: 8,
    logo: FaCup,
  },
  'uefa - european championship': {
    name: 'UEFA - European Championship',
    index: 10,
    logo: EuropeanChampionship,
    teamLogo: {
      Germany: Germany,
      Scotland: Scotland,
      Hungary: Hungary,
      Switzerland: Switzerland,
      Spain: Spain,
      Croatia: Crotia,
      Italy: Italy,
      Albania: Albania,
      Poland: Poland,
      Netherlands: Netherlands,
      Slovenia: Slovenia,
      Denmark: Denmark,
      Serbia: Serbia,
      England: England,
      Austria: Austria,
      Belgium: Belgium,
      Czechia: Czechia,
      France: France,
      Georgia: Georgia,
      Portugal: Portugal,
      Romania: Romania,
      Slovakia: Slovakia,
      Turkey: Turkey,
      Türkiye: Turkey,
      Ukraine: Ukraine,
    },
  },
  'uefa - nations league': {
    name: 'UEFA - Nations League',
    index: 11,
    logo: NationsLeague,
    teamLogo: {
      Germany: Germany,
      Lithuania: Lithuania,
      Norway: Norway,
      Greece: Greece,
      Ukraine: Ukraine,
      'Republic of Ireland': Ireland,
      Latvia: Latvia,
      'Faroe Islands': FaroeIslands,
      Finland: Finland,
      'Bosnia-Herzegovina': Bosnia,
      Andorra: Andorra,
      Malta: Malta,
      'North Macedonia': Macedonia,
      Armenia: Armenia,
      Wales: Wales,
      Kazakhstan: Kazakhstan,
      Kosovo: Kosovo,
      Montenegro: Montenegro,
      Iceland: Iceland,
      Cyprus: Cyprus,
      Israel: Israel,
      Scotland: Scotland,
      Hungary: Hungary,
      Switzerland: Switzerland,
      Spain: Spain,
      Croatia: Crotia,
      Italy: Italy,
      Albania: Albania,
      Poland: Poland,
      Netherlands: Netherlands,
      Slovenia: Slovenia,
      Denmark: Denmark,
      Serbia: Serbia,
      England: England,
      Austria: Austria,
      Belgium: Belgium,
      Czechia: Czechia,
      France: France,
      Georgia: Georgia,
      Portugal: Portugal,
      Romania: Romania,
      Slovakia: Slovakia,
      Turkey: Turkey,
      Türkiye: Turkey,
    },
  },
  'uefa - champions league': {
    name: 'UEFA - Champions League',
    index: 12,
    logo: ChampionsLeague,
    abbreviated_name: {
      'AC Milan': 'MIL',
      'Ajax Amsterdam': 'AJA',
      'Atletico Madrid': 'ATM',
      Barcelona: 'FCB',
      'Bayer Leverkusen': 'B04',
      'Bayern Munich': 'BAY',
      Benfica: 'SLB',
      'Borussia Dortmund': 'DOR',
      Celtic: 'CEL',
      Chelsea: 'CHE',
      'Club Brugge': 'BRU',
      'Dinamo Zagreb': 'DZA',
      'Eintracht Frankfurt': 'SGE',
      'FC Copenhagen': 'COP',
      'FC Porto': 'POR',
      'FC Salzburg': 'RBS',
      'Inter Milan': 'INT',
      Juventus: 'JUV',
      Liverpool: 'LIV',
      'Maccabi Haifa': 'HAI',
      'Manchester City': 'MCI',
      Napoli: 'NAP',
      'Olympique Marseille': 'OLM',
      'Paris St Germain': 'PSG',
      Rangers: 'RFC',
      'RB Leipzig': 'RBL',
      'Real Madrid': 'MAD',
      Sevilla: 'SEV',
      'Shakhtar Donetsk': 'SHK',
      'Sporting Lisbon': 'SLI',
      'Tottenham Hotspur': 'TOT',
      'Viktoria Plzen': 'VPL',
    },
  },
  'uefa - europa league': {
    name: 'UEFA - Europa League',
    index: 13,
    logo: EuropaLeague,
    abbreviated_name: {
      'AEK Larnaca': 'LAR',
      Arsenal: 'ARS',
      Roma: 'ROM',
      'Bodo/Glimt': 'BOD',
      'Dynamo Kyiv': 'DYK',
      'FC Midtjylland': 'FCM',
      Fenerbahce: 'FEN',
      Ferencvaros: 'FER',
      Juventus: 'JUV',
      Sevilla: 'SEV',
      Feyenoord: 'FEY',
      Freiburg: 'SCF',
      HJK: 'HJK',
      Lazio: 'LAZ',
      'Ludogorets Razgrad': 'LUD',
      'Malmo FF': 'MAL',
      'Manchester United': ' MUN',
      Monaco: 'AMO',
      Nantes: 'NAN',
      Olympiacos: 'OLY',
      Omonia: 'OMO',
      'PSV Eindhoven': 'PSV',
      Qarabag: 'QAR',
      'Real Betis': 'BET',
      'Real Sociedad': 'SOC',
      'Red Star Belgrade': 'RSB',
      Rennes: 'REN',
      'Sheriff Tiraspol': 'SHE',
      'Sporting Braga': 'SBR',
      'Sturm Graz': 'SGR',
      Trabzonspor: 'TRA',
      'Union Berlin': 'UNB',
      'Union SG': 'USG',
      Zurich: 'ZUR',
    },
  },
  'uefa - europa conference league': {
    name: 'UEFA - Europa Conference League',
    index: 14,
    logo: EuropaConference,
  },
  'conmebol - copa america': {
    name: 'CONMEBOL - Copa America',
    index: 15,
    logo: CopaAmerica,
    teamLogo: {
      Argentina: Argentina,
      Bolivia: Bolivia,
      Brazil: Brazil,
      Chile: Chile,
      Canada: Canada,
      Colombia: Colombia,
      'Costa Rica': CostaRica,
      Ecuador: Ecuador,
      'United States': UnitedStates,
      Jamaica: Jamaica,
      Mexico: Mexico,
      Panama: Panama,
      Paraguay: Paraguay,
      Peru: Peru,
      Uruguay: Uruguay,
      Venezuela: Venezuela,
    },
  },
  'conmebol - copa libertadores': {
    name: 'CONMEBOL - Copa Libertadores',
    index: 16,
    logo: ConmebolLibertadores,
  },
  'conmebol - copa sudamericana': {
    name: 'CONMEBOL - Copa Sudamericana',
    index: 17,
    logo: ConmebolSudamericana,
  },
  'usa - major league soccer': {
    name: 'USA - Major League Soccer',
    index: 18,
    logo: Mls,
    abbreviated_name: {
      'Atlanta United FC': 'ATL',
      'Austin FC': 'AUS',
      'CF Montreal': 'MTL',
      'Charlotte FC': 'CHL',
      'Chicago Fire FC': 'CHI',
      'Colorado Rapids': 'CLR',
      'Columbus Crew SC': 'COL',
      'Columbus Crew': 'COL',
      'D.C. United': 'DCU',
      'FC Cincinnati': 'CIN',
      'FC Dallas': 'DAL',
      'Houston Dynamo FC': 'HOU',
      'Inter Miami CF': 'MIA',
      'Los Angeles FC': 'LAF',
      'Los Angeles Galaxy': 'LAG',
      'Minnesota United FC': 'MIN',
      'Nashville SC': 'NSC',
      'New England Revolution': 'NER',
      'New York City': 'NYC',
      'New York Red Bulls': 'NYR',
      'Orlando City SC': 'ORL',
      'Philadelphia Union': 'PHI',
      'Portland Timbers': 'POT',
      'Real Salt Lake': 'RSL',
      'San Jose Earthquakes': 'SJE',
      'Seattle Sounders FC': 'SEA',
      'Sporting Kansas City': 'SKC',
      'St. Louis City SC': 'LOU',
      'Toronto FC': 'TFC',
      'Vancouver Whitecaps FC': 'VAN',
    },
  },
  'saudi arabia - saudi league': {
    name: 'Saudi Arabia - Saudi League',
    index: 19,
    logo: SaudiLeague,
  },
  'australia - a-league': {
    name: 'Australia - A-League',
    index: 20,
    logo: AustraliaLeague,
  },
  'mexico - liga mx': {
    name: 'Mexico - Liga MX',
    index: 21,
    logo: MexicoLigaMx,
  },
  'england - league 1': {
    name: 'England - League 1',
    index: 22,
    logo: EnglandLeague1,
  },
  'portugal - primeira liga': {
    name: 'Portugal - Primeira Liga',
    index: 23,
    logo: PortugalLeague,
  },
  'japan - j1 league': {
    name: 'Japan - J1 League',
    index: 24,
    logo: JapanLeague,
  },
  'italy - serie b': {
    name: 'Italy - Serie B',
    index: 25,
    logo: ItalySerieB,
  },
  'netherlands - eredivisie': {
    name: 'Nerherlands - Eredivisie',
    index: 26,
    logo: NetherlandsEredivisie,
  },
  'netherlands - eerste divisie': {
    name: 'Nerherlands - Eerste Divisie',
    index: 27,
    logo: EersteDivisie,
  },
  'turkey - 1. lig': {
    name: 'Turkey - 1. Lig',
    index: 28,
    logo: Turkey1League,
  },
  'denmark - superliga': {
    name: 'Denmark - Superliga',
    index: 29,
    logo: DenmarkSuperliga,
  },
  'germany - bundesliga 2': {
    name: 'Germany - Bundesliga 2',
    index: 30,
    logo: Bundesliga2,
  },
  'france - ligue 2': {
    name: 'France - Ligue 2',
    index: 31,
    logo: FranceLigue2,
  },
  'switzerland - super league': {
    name: 'Switzerland - Super League',
    index: 32,
    logo: SwitzerlandLeague,
  },
  'scotland - premiership': {
    name: 'Scotland - Premiership',
    index: 33,
    logo: ScotlandPremiership,
  },
  'czech republic - fnl': {
    name: 'Czech Republic - FNL',
    index: 34,
    logo: CzechFnl,
  },
  'ireland - premier league': {
    name: 'Ireland - Premier League',
    index: 35,
    logo: IrelandLeague,
  },
  'brazil - serie a': {
    name: 'Brazil - Serie A',
    index: 36,
    logo: BrazilSerieA,
  },
  'argentina - primera division': {
    name: 'Argentina - Primera Division',
    index: 37,
    logo: ArgentinaPrimera,
  },
  'croatia - 1. hnl': {
    name: 'Croatia - 1. HNL',
    index: 38,
    logo: CrotiaHnl,
  },
  'turkey - super lig': {
    name: 'Turkey - Super Lig',
    index: 39,
    logo: SuperLig,
  },
  'belgium - jupiler pro league': {
    name: 'Belgium - Jupiler Pro League',
    index: 40,
    logo: BelgiumJupilerPro,
  },
  'international - club friendlies': {
    name: 'International - Club Friendlies',
    index: 41,
    logo: ClubFriendlies,
  },
  'olympics soccer men': {
    name: 'Olympics Soccer Men',
    index: 42,
    logo: OlympicsParis,
    teamLogo: {
      Iraq: Iraq,
      Ukraine: Ukraine,
      Japan: Japan,
      Paraguay: Paraguay,
      Mali: Mali,
      Israel: Israel,
      France: France,
      'United States': Us,
      Egypt: Egypt,
      Spain: Spain,
      Argentina: Argentina,
      Morocco: Morocco,
    },
  },
}

export const COUNTRIES = {
  canada: {
    name: 'Canada',
    flag: '/countries/canada.svg',
  },
  world: {
    name: 'International',
    flag: '/svgs/soccer-ball-svgrepo-com.svg',
  },
  england: {
    name: 'England',
    flag: '/countries/england.svg',
  },
  france: {
    name: 'France',
    flag: '/countries/france.svg',
  },
  germany: {
    name: 'Germany',
    flag: '/countries/germany.svg',
  },
  italy: {
    name: 'Italy',
    flag: '/countries/italy.svg',
  },
  spain: {
    name: 'Spain',
    flag: '/countries/spain.svg',
  },
  usa: {
    name: 'USA',
    flag: '/countries/usa.svg',
  },
}

export const SPORTS = {
  football: {
    index: 1,
    name: 'football',
    logo: Football,
  },
  basketball: {
    index: 2,
    name: 'basketball',
    logo: Basketball,
  },
  hockey: {
    index: 3,
    name: 'hockey',
    logo: Hockey,
  },
  baseball: {
    index: 4,
    name: 'baseball',
    logo: Baseball,
  },
  soccer: {
    index: 5,
    name: 'soccer',
    logo: GameHeader,
  },
  boxing: {
    index: 6,
    name: 'boxing',
    logo: Boxing,
  },
  mma: {
    index: 7,
    name: 'mma',
    logo: Mma,
  },
  // esports: {
  //   index: 8,
  //   name: 'esports',
  //   logo: '/svgs/esport.svg',
  // },
  motorsports: {
    index: 8,
    name: 'motorsports',
    logo: F1,
  },
  golf: {
    index: 9,
    name: 'golf',
    logo: Golf,
  },
}

const correctNames = {
  Plymouth: 'Plymouth Argyle',
  Ipswich: 'Ipswich Town',
  'Sheffield United': 'Sheffield Wednesday',
  Leicester: 'Leicester City',
  Leeds: 'Leeds United',
  'Nottingham Forest FC': 'Nottingham Forest',
  'Burnley FC': 'Burnley',
  'Crystal Palace FC': 'Crystal Palace',
  'Fulham FC': 'Fulham',
  'Luton Town FC': 'Luton Town',
  'Wolverhampton Wanderers FC': 'Wolverhampton Wanderers',
  'Manchester City FC': 'Manchester City',
  'Brentford FC': 'Brentford',
  'Everton FC': 'Everton',
  'Manchester United FC': 'Manchester United',
  'Arsenal FC': 'Arsenal',
  'Tottenham Hotspur FC': 'Tottenham Hotspur',
  'Brighton & Hove Albion FC': 'Brighton Hove Albion',
  'Chelsea FC': 'Chelsea',
  'Aston Villa FC': 'Aston Villa',
  'Liverpool FC': 'Liverpool',
  'West Ham United FC': 'West Ham United',
  'Sheffield United FC': 'Sheffield United',
  'Newcastle United FC': 'Newcastle United',
  'Stoke City FC': 'Stoke City',
  'Hull City AFC': 'Hull City',
  'Sunderland AFC': 'Sunderland',
  'Cardiff City FC': 'Cardiff City',
  'Coventry City FC': 'Coventry City',
  'Huddersfield Town FC': 'Huddersfield Town',
  'Sheffield Wednesday FC': 'Sheffield Wednesday',
  'Southampton FC': 'Southampton',
  'Leeds United FC': 'Leeds United',
  'Bristol City FC': 'Bristol City',
  'Rotherham United FC': 'Rotherham United',
  'Plymouth Argyle FC': 'Plymouth Argyle',
  'Millwall FC': 'Millwall',
  'Swansea City AFC': 'Swansea City',
  'Norwich City FC': 'Norwich City',
  'Birmingham City FC': 'Birmingham City',
  'Preston North End FC': 'Preston North End',
  'Queens Park Rangers FC': 'Queens Park Rangers',
  'Watford FC': 'Watford',
  'Middlesbrough FC': 'Middlesbrough',
  'West Bromwich Albion FC': 'West Bromwich Albion',
  'VfB Stuttgart 1893': 'VfB Stuttgart',
  'SV Darmstadt 1898': 'SV Darmstadt 98',
  'TSG 1899 Hoffenheim': 'TSG Hoffenheim',
  'BV Borussia 09 Dortmund': 'Borussia Dortmund',
  'Borussia VfL Mönchengladbach': 'Borussia Monchengladbach',
  'Rasen Ballsport Leipzig': 'Rb Leipzig',
  '1. FSV Mainz 05': 'Mainz',
  'FC Bayern München': 'Bayern Munich',
  'VfL Bochum 1848': 'VfL Bochum',
  'SV Werder Bremen': 'Werder Bremen',
  '1. FC Köln': 'FC Cologne',
  'Union Berlin': '1. FC Union Berlin',
  'FC Bayern Munich': 'Bayern Munich',
  'Bayer 04 Leverkusen': 'Bayer Leverkusen',

  'AS Monaco FC': 'AS Monaco',
  "OGC Nice Côte d'Azur": 'Nice',
  'FC Nantes': 'Nantes',
  'FC Lorient': 'Lorient',
  'Stade Brestois 29': 'Brest',
  'Olympique Lyonnais': 'Lyon',
  'FC Lorient': 'Lorient',
  'FC Metz': 'Metz',
  'RC Strasbourg Alsace': 'Strasbourg',
  'Clermont Foot 63': 'Clermont Foot',
  'Racing Club de Lens': 'Lens',
  'Toulouse FC': 'Toulouse',
  'Montpellier HSC': 'Montpellier',
  'Stade Rennais FC': 'Stade Rennais',
  'Paris Saint-Germain FC': 'Paris Saint-Germain',
  'Olympique de Marseille': 'Marseille',
  'Lille OSC': 'Lille',
  PSV: 'Paris Saint-Germain',

  'US Salernitana 1919': 'Salernitana',
  'Torino FC': 'Torino',
  'Hellas Verona FC': 'Hellas Verona',
  'Bologna FC 1909': 'Bologna',
  'US Salernitana 1919': 'US Salernitana',
  'Frosinone Calcio': 'Frosinone',
  'US Lecce': 'Lecce',
  'Genoa CFC': 'Genoa',
  'US Sassuolo Calcio': 'Sassuolo',
  'Juventus FC': 'Juventus',
  'SS Lazio': 'Lazio',
  'AC Monza': 'Monza',
  'Empoli FC': 'Empoli',
  'FC Internazionale Milano': 'Internazionale',
  'Atalanta Bergamasca Calcio': 'Atalanta',
  'Cagliari Calcio': 'Cagliari',
  'Udinese Calcio': 'Udinese',
  'ACF Fiorentina': 'Fiorentina',
  'Bologna FC 1909': 'Bologna',
  'SSC Napoli': 'Napoli',
  'Torino FC': 'Torino',
  'US Salernitana 1919': 'Salernitana',

  'SC Braga': 'Braga',
  'Royal Antwerp FC': 'Antwerp',
  'Royal Antwerp': 'Antwerp',
  'Olympiakos CFP': 'Olympiakos',
  'Rangers FC': 'Rangers',
  'FK Sheriff Tiraspol': 'Sheriff Tiraspol',

  'Club Internacional de Fútbol Miami': 'Inter Miami CF',
  'Club de Foot Montréal': 'CF Montr Al',
  'DC United': 'D C United',
  'New York City Football Club': 'New York City FC',
  'Vancouver Whitecaps FC': 'Vancouver Whitecaps',
  'Los Angeles FC': 'LAFC',

  'Sevilla FC': 'Sevilla',
  'Club Atlético de Madrid': 'Atletico Madrid',
  'Granada CF': 'Granada',
  'Girona FC': 'Girona',
  'Real Club Deportivo Mallorca': 'Mallorca',
  'CA Osasuna': 'Osasuna',
  'FC Barcelona': 'Barcelona',
  'Real Club Celta de Vigo': 'Celta Vigo',
  'Valencia CF': 'Valencia',
  'Getafe CF': 'Getafe',
  'Villarreal CF': 'Villarreal',
  'Real Betis Balompié': 'Real Betis',
  'UD Las Palmas': 'Las Palmas',
  'Real Madrid CF': 'Real Madrid',
  'Real Sociedad de Fútbol': 'Real Sociedad',
  'Panathinaikos FC': 'Panathinaikos',
  'Molde FK': 'Molde',
  'Servette FC': 'Servette',
  'Maccabi Haifa FC': 'Maccabi Haifa',
  'AEK Athens FC': 'AEK Athens',
  'Galatasaray Spor Kulübü': 'Galatasaray',
  'Sporting Braga': 'Braga',
  'FC Shakhtar Donetsk': 'Shakhtar Donetsk',
  'Celtic FC': 'Celtic',
  'BSC Young Boys': 'Young Boys',
}

const getLeaguesIndex = () => {
  const LeaguesIndexes = {}
  Object.keys(LEAGUES).forEach((tempLeague) => {
    LeaguesIndexes[tempLeague] = LEAGUES[tempLeague].index
  })

  return LeaguesIndexes
}

export const LEAGUES_INDEXES = getLeaguesIndex()

export const getLeagueByName = (leagueName) => {
  const lowerLeague = leagueName?.toLowerCase()
  return LEAGUES[lowerLeague]
    ? LEAGUES[lowerLeague]
    : {
        name: leagueName,
        index: 1,
        logo: Lbt,
      }
}

export const getSportByName = (sportName) => {
  const lowerSport = sportName?.toLowerCase()
  return SPORTS[lowerSport]
    ? SPORTS[lowerSport]
    : {
        name: sportName,
        index: 1,
        logo: Lbt,
      }
}

export const getCountryByName = (countryName) => {
  const lowerCountry = countryName?.toLowerCase()
  return COUNTRIES[lowerCountry]
    ? COUNTRIES[lowerCountry]
    : {
        name: countryName,
        index: 1,
        logo: Lbt,
      }
}

export const getTeamByName = ({ teamName, league, sport }) => {
  const lowerTeam =
    teamName && teamName?.toLowerCase().replace(/ /g, '_').replace(/[.]/g, '')
  const lowerLeague = league?.toLowerCase()
  if (sport === 'football') {
    if (league === 'NCAAF') {
      const logo = LEAGUES['ncaaf'].teamLogo[teamName]
      return {
        name: teamName,
        index: 1,
        logo: `https://cdn.oddsjam.com/team-logos/${lowerLeague}/${logo}.png`,
      }
    } else {
      return {
        name: teamName,
        index: 1,
        logo: `https://cdn.oddsjam.com/team-logos/${lowerLeague}/${lowerTeam}.png`,
      }
    }
  } else if (sport === 'soccer') {
    const lowerLeague = league?.toLowerCase().replace(/ /g, '_')
    let lwTeamName = ''
    if (correctNames[teamName]) {
      lwTeamName = correctNames[teamName]
        ?.toLowerCase()
        .replace(/ /g, '_')
        .replace(/[.]/g, '')
    } else {
      lwTeamName =
        teamName &&
        teamName?.toLowerCase().replace(/ /g, '_').replace(/[.]/g, '')
    }

    if (league === 'FIFA - World Cup') {
      return {
        name: teamName,
        index: 1,
        logo: LEAGUES['fifa - world cup']?.teamLogo?.[teamName],
      }
    } else if (league === 'UEFA - European Championship') {
      return {
        name: teamName,
        index: 1,
        logo: LEAGUES['uefa - european championship'].teamLogo[teamName],
      }
    } else if (league === 'CONMEBOL - Copa America') {
      return {
        name: teamName,
        index: 1,
        logo: LEAGUES['conmebol - copa america'].teamLogo[teamName],
      }
    } else if (league === 'UEFA - Nations League') {
      return {
        name: teamName,
        index: 1,
        logo: LEAGUES['uefa - nations league'].teamLogo[teamName],
      }
    } else if (league === 'Olympics Soccer Men') {
      const removedExtraLines = teamName.replace(' U23', '')
      return {
        name: teamName,
        index: 1,
        logo: LEAGUES['olympics soccer men'].teamLogo[removedExtraLines],
      }
    } else {
      return {
        name: teamName,
        index: 1,
        logo: `https://cdn.oddsjam.com/team-logos/${lowerLeague}/${lwTeamName}.png`,
      }
    }
  } else if (sport === 'mma') {
    const logo = LEAGUES['ufc'].player_images[teamName]
    return {
      name: teamName,
      index: 1,
      logo: `https://a.espncdn.com/i/headshots/mma/players/full/${logo}.png`,
    }
  } else if (sport === 'basketball') {
    if (league === 'NCAAB') {
      const logo = TEAM_LOGOS_NCAAB_BASKETBALL[teamName]
      return {
        name: teamName,
        index: 1,
        logo: `https://a.espncdn.com/i/teamlogos/ncaa/500/${logo}.png`,
      }
    } else if (league === 'Olympics Basketball Men') {
      return {
        name: teamName,
        index: 1,
        logo: LEAGUES['olympics basketball men'].teamLogo[teamName],
      }
    } else {
      return {
        name: teamName,
        index: 1,
        logo: `https://cdn.oddsjam.com/team-logos/${lowerLeague}/${lowerTeam}.png`,
      }
    }
  } else {
    return {
      name: teamName,
      index: 1,
      logo: `https://cdn.oddsjam.com/team-logos/${lowerLeague}/${lowerTeam}.png`,
    }
  }
}

export const getTeamAbbreviation = (league, teamName) => {
  const normalizedLeague = league?.toLowerCase()
  if (LEAGUES.hasOwnProperty(normalizedLeague)) {
    const leagueObj = LEAGUES[normalizedLeague]

    if (
      leagueObj.hasOwnProperty('abbreviated_name') &&
      leagueObj.abbreviated_name.hasOwnProperty(teamName)
    ) {
      return leagueObj.abbreviated_name[teamName]
    } else {
      return `${teamName}`
    }
  } else {
    return `No league found with name: ${league}`
  }
}
